@import "../../../styles/modules";

@mixin gradient {
  background: linear-gradient(
    to right,
    rgba(52, 58, 64, 1) 0%,
    rgba(255, 255, 255, 0) 100%
  );
}

.carousel {
  background-color: $dark;
  height: 35%;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0;

  @include media-breakpoint-up(lg) {
    border-radius: 16px;
    width: 75vw;
    height: 23%;
  }

  @include media-breakpoint-up(xxl) {
    width: 70vw;
  }

  &__container {
    height: 200px;
    max-height: 100%;
    position: relative;
    width: 85vw;

    @include media-breakpoint-up(sm) {
      width: 100vw;
    }
    @include media-breakpoint-up(md) {
      width: 90vw;
    }
    @include media-breakpoint-up(lg) {
      width: 70vw;
    }
    @include media-breakpoint-up(xl) {
      width: 65vw;
    }

    &::before,
    &::after {
      @include gradient;
      content: "";
      height: 100%;
      position: absolute;
      width: 60px;
      z-index: 2;
      top: 0;

      @include media-breakpoint-up(sm) {
        width: 150px;
      }
      @include media-breakpoint-up(md) {
        width: 190px;
      }
      @include media-breakpoint-up(lg) {
        width: 250px;
      }
      @include media-breakpoint-up(xl) {
        width: 300px;
      }
    }

    &::after {
      right: 0;
      transform: rotateZ(180deg);
    }

    &::before {
      left: 2;
    }
  }

  &__slide {
    display: flex !important;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 100%;
  }

  &__line {
    height: 10vh;
    width: 0;
    border: solid $primary 0.6px;
  }

  &__item {
    display: flex;
    justify-content: center;
    height: 200px;
    width: 100%;
    align-items: center;
    padding-inline: 0;
      @include media-breakpoint-up(sm) {
      padding-inline: $spacer-6;
    }
    @include media-breakpoint-up(xl) {
      padding-inline: $spacer-8;
    }
  }

  &__number {
    color: $white;
    text-align: center;
    font-size: 2.5rem;
    font-weight: $font-weight-bold;
    line-height: $line-height-sm;
    margin-block-end: 0;
  }

  &__text {
    color: $white;
    text-align: center;
    font-size: 1.25rem;
    font-weight: $font-weight-normal;
    line-height: $line-height-sm;
    margin-block-end: 0;
  }
}
