$white: #fff;
$gray-100: #f8f9fa;
$gray-200: #e9ecef;
$gray-300: #dee2e6;
$gray-400: #c3cbcd;
$gray-500: #adb5bd;
$gray-600: #6c757d;
$gray-700: #495057;
$gray-800: #343a40;
$gray-900: #373f41;
$black: #000;

$dark-blue: #233f82;
$light-blue: #239ed4;
$dark-gray: #343a40;
$light-gray: #c7d2d4;

$grays: (
  "100": $gray-100,
  "200": $gray-200,
  "300": $gray-300,
  "400": $gray-400,
  "500": $gray-500,
  "600": $gray-600,
  "700": $gray-700,
  "800": $gray-800,
  "900": $gray-900,
);

$blue: #0d6efd;
$indigo: #6610f2;
$purple: #6f42c1;
$pink: #d63384;
$red: #dc3545;
$orange: #fd7e14;
$yellow: #ffc107;
$green: #198754;
$teal: #20c997;
$cyan: #0dcaf0;
// scss-docs-end color-variables

// scss-docs-start colors-map
$colors: (
  "blue": $blue,
  "indigo": $indigo,
  "purple": $purple,
  "pink": $pink,
  "red": $red,
  "orange": $orange,
  "yellow": $yellow,
  "green": $green,
  "teal": $teal,
  "cyan": $cyan,
  "white": $white,
  "gray": $gray-600,
  "gray-dark": $gray-800,
);

$primary: #fedf00;
$secondary: $dark-gray;
$success: $green;
$info: $light-blue;
$warning: $dark-blue;
$danger: $red;
$light: $light-gray;
$dark: $dark-gray;

$theme-colors: (
  "primary": $primary,
  "secondary": $secondary,
  "success": $success,
  "info": $info,
  "warning": $warning,
  "danger": $danger,
  "light": $light,
  "dark": $dark,
);

$text-muted: $gray-400;

// Spacers and layout
$spacer: 1rem;

$spacer-0: 0;
$spacer-1: $spacer * 0.25;
$spacer-2: $spacer * 0.5;
$spacer-3: $spacer;
$spacer-4: $spacer * 1.5;
$spacer-5: $spacer * 2;
$spacer-6: $spacer * 2.5;
$spacer-7: $spacer * 3;
$spacer-8: $spacer * 3.5;
$spacer-9: $spacer * 4.5;
$spacer-10: $spacer * 6;

$spacers: (
  0: $spacer-0,
  1: $spacer-1,
  2: $spacer-2,
  3: $spacer-3,
  4: $spacer-4,
  5: $spacer-5,
  6: $spacer-6,
  7: $spacer-7,
  8: $spacer-8,
  9: $spacer-9,
  10: $spacer-10,
);

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1197px,
  //   xxl: 1320px,
);

$grid-gutter-width: 1.5rem;

$container-padding-x: $grid-gutter-width * 1;

// Borders
$border-radius: 0.25rem;
$border-radius-sm: 0.2rem;
$border-radius-md: $spacer-2;

$border-radius-lg: $spacer-4;
$border-radius-pill: 50rem;

$border-width: 1px;
$border-widths: (
  1: 1px,
  2: 2px,
  3: 3px,
  4: 4px,
  5: 5px,
);

$component-active-color: $white;
$component-active-bg: $primary;

$box-shadow: 0 0.5rem 1rem rgba($black, 0.15);
$box-shadow-sm: 0 0.125rem 0.25rem rgba($black, 0.075);
$box-shadow-lg: 0 1rem 3rem rgba($black, 0.175);
$box-shadow-inset: inset 0 1px 2px rgba($black, 0.075);

$body-bg: $white;
$body-color: $gray-900;
$body-text-align: null;

// Fonts
$font-size-root: null;
$font-size-base: 1rem;
$font-size-sm: $font-size-base * 0.875;
$font-size-lg: $font-size-base * 1.25;

$small-font-size: 0.875em;

$font-weight-lighter: lighter;
$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-semibold: 500;
$font-weight-bold: 700;
$font-weight-bolder: bolder;

$font-weight-base: $font-weight-normal;

$line-height-base: 1.6;
$line-height-sm: 1.25;
$line-height-lg: 2;

$h1-font-size: $font-size-base * 2.25;
$h2-font-size: $font-size-base * 2.25;
$h3-font-size: $font-size-base * 2;
$h4-font-size: $font-size-base * 1.5;
$h5-font-size: $font-size-base * 1.25;
$h6-font-size: $font-size-base * 1.125;
$h7-font-size: $font-size-base * 0.875;
$h8-font-size: $font-size-base * 0.75;

$font-sizes: (
  1: $h1-font-size,
  2: $h2-font-size,
  3: $h3-font-size,
  4: $h4-font-size,
  5: $h5-font-size,
  6: $h6-font-size,
  7: $h7-font-size,
  8: $h8-font-size,
);

$headings-margin-bottom: $spacer * 1.5;
$headings-font-family: null;
$headings-font-style: null;
$headings-font-weight: 500;
$headings-line-height: 1.2;
$headings-color: $black;
// scss-docs-end headings-variables

// scss-docs-start display-headings
$display-font-sizes: (
  1: 5rem,
  2: 4.5rem,
  3: 4rem,
  4: 3.5rem,
  5: 3rem,
  6: 2rem,
);

$display-font-weight: 400;
$display-line-height: $headings-line-height;

// Buttons
$input-btn-padding-y: 0.5rem;
$input-btn-padding-x: 2rem;
$input-btn-font-family: null;
$input-btn-font-size: $font-size-base;
$input-btn-line-height: $line-height-base;

$input-btn-focus-width: 0.25rem;
$input-btn-focus-color-opacity: 0.25;
$input-btn-focus-blur: 0;

$input-btn-padding-y-sm: 0.5rem;
$input-btn-padding-x-sm: 1rem;
$input-btn-font-size-sm: $font-size-sm;

$input-btn-padding-y-lg: 0.5rem;
$input-btn-padding-x-lg: 1rem;
$input-btn-font-size-lg: $font-size-lg;

$input-btn-focus-width: 0.25rem;
$input-btn-focus-color-opacity: 0.25;
$input-btn-focus-color: rgba(
  $component-active-bg,
  $input-btn-focus-color-opacity
);
$input-btn-focus-blur: 0;
$input-btn-focus-box-shadow: 0 0 $input-btn-focus-blur $input-btn-focus-width
  $input-btn-focus-color;

$input-btn-focus-box-shadow: 0 0 $input-btn-focus-blur $input-btn-focus-width
  $input-btn-focus-color;

$btn-padding-y: $input-btn-padding-y;
$btn-padding-x: $input-btn-padding-x;
$btn-font-family: $input-btn-font-family;
$btn-font-size: $input-btn-font-size;
$btn-line-height: $input-btn-line-height;
$btn-white-space: null; // Set to `nowrap` to prevent text wrapping

$btn-padding-y-sm: $input-btn-padding-y-sm;
$btn-padding-x-sm: $input-btn-padding-x-sm;
$btn-font-size-sm: $input-btn-font-size-sm;

$btn-padding-y-lg: $input-btn-padding-y-lg;
$btn-padding-x-lg: $input-btn-padding-x-lg;
$btn-font-size-lg: $input-btn-font-size-lg;

$input-btn-border-width: $border-width;
$btn-border-width: 2px;

$btn-font-weight: $font-weight-semibold;
$btn-box-shadow: inset 0 1px 0 rgba($white, 0.15), 0 1px 1px rgba($black, 0.075);
$btn-focus-width: $input-btn-focus-width;
$btn-disabled-opacity: 0.65;
$btn-active-box-shadow: inset 0 3px 5px rgba($black, 0.125);

$btn-link-disabled-color: $gray-600;

$btn-border-radius: $border-radius-pill;

$btn-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
  border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

$btn-hover-bg-shade-amount: 15%;
$btn-hover-bg-tint-amount: 15%;
$btn-hover-border-shade-amount: 20%;
$btn-hover-border-tint-amount: 10%;
$btn-active-bg-shade-amount: 20%;
$btn-active-bg-tint-amount: 20%;
$btn-active-border-shade-amount: 25%;
$btn-active-border-tint-amount: 10%;

// Forms

// scss-docs-start form-text-variables
$form-text-margin-top: 0.25rem;
$form-text-font-size: $small-font-size;
$form-text-font-style: null;
$form-text-font-weight: null;
$form-text-color: $text-muted;
// scss-docs-end form-text-variables

// scss-docs-start form-label-variables
$form-label-margin-bottom: 0.5rem;
$form-label-font-size: null;
$form-label-font-style: null;
$form-label-font-weight: null;
$form-label-color: null;
// scss-docs-end form-label-variables

// scss-docs-start form-input-variables
$input-padding-y: $spacer-3;
$input-padding-x: $input-btn-padding-x;
$input-font-family: $input-btn-font-family;
$input-font-size: $input-btn-font-size;
$input-font-weight: $font-weight-base;
$input-line-height: $input-btn-line-height;

$input-padding-y-sm: $input-btn-padding-y-sm;
$input-padding-x-sm: $input-btn-padding-x-sm;
$input-font-size-sm: $input-btn-font-size-sm;

$input-padding-y-lg: $input-btn-padding-y-lg;
$input-padding-x-lg: $input-btn-padding-x-lg;
$input-font-size-lg: $input-btn-font-size-lg;

$input-bg: $gray-100;
$input-disabled-bg: $gray-200;
$input-disabled-border-color: null;

$input-color: $body-color;
$input-border-color: $gray-400;
$input-border-width: 0px;
$input-box-shadow: $box-shadow-inset;

$input-border-radius: $border-radius-lg;
$input-border-radius-sm: $border-radius-sm;
$input-border-radius-lg: $border-radius-lg;

$input-focus-bg: $input-bg;
$input-focus-border-color: tint-color($component-active-bg, 50%);
$input-focus-color: $input-color;
$input-focus-width: $input-btn-focus-width;
$input-focus-box-shadow: $input-btn-focus-box-shadow;

$input-placeholder-color: $gray-600;
$input-plaintext-color: $body-color;

$input-height-border: $input-border-width * 2;

$input-height-inner: add($input-line-height * 1em, $input-padding-y * 2);
$input-height-inner-half: add($input-line-height * 0.5em, $input-padding-y);
$input-height-inner-quarter: add(
  $input-line-height * 0.25em,
  $input-padding-y * 0.5
);

$input-height: add(
  $input-line-height * 1em,
  add($input-padding-y * 2, $input-height-border, false)
);
$input-height-sm: add(
  $input-line-height * 1em,
  add($input-padding-y-sm * 2, $input-height-border, false)
);
$input-height-lg: add(
  $input-line-height * 1em,
  add($input-padding-y-lg * 2, $input-height-border, false)
);

$input-transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

$form-color-width: 3rem;
// scss-docs-end form-input-variables

// scss-docs-start form-check-variables
$form-check-input-width: 1em;
$form-check-min-height: $font-size-base * $line-height-base;
$form-check-padding-start: $form-check-input-width + 0.5em;
$form-check-margin-bottom: 0.125rem;
$form-check-label-color: null;
$form-check-label-cursor: null;
$form-check-transition: null;

$form-check-input-active-filter: brightness(90%);

$form-check-input-bg: $input-bg;
$form-check-input-border: 1px solid rgba($black, 0.25);
$form-check-input-border-radius: 0.25em;
$form-check-radio-border-radius: 50%;
$form-check-input-focus-border: $input-focus-border-color;
$form-check-input-focus-box-shadow: $input-btn-focus-box-shadow;

$form-check-input-checked-color: $component-active-color;
$form-check-input-checked-bg-color: $component-active-bg;
$form-check-input-checked-border-color: $form-check-input-checked-bg-color;
$form-check-input-checked-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'><path fill='none' stroke='#{$form-check-input-checked-color}' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/></svg>");
$form-check-radio-checked-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='2' fill='#{$form-check-input-checked-color}'/></svg>");

$form-check-input-indeterminate-color: $component-active-color;
$form-check-input-indeterminate-bg-color: $component-active-bg;
$form-check-input-indeterminate-border-color: $form-check-input-indeterminate-bg-color;
$form-check-input-indeterminate-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'><path fill='none' stroke='#{$form-check-input-indeterminate-color}' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/></svg>");

$form-check-input-disabled-opacity: 0.5;
$form-check-label-disabled-opacity: $form-check-input-disabled-opacity;
$form-check-btn-check-disabled-opacity: $btn-disabled-opacity;

$form-check-inline-margin-end: 1rem;
// scss-docs-end form-check-variables

// scss-docs-start form-switch-variables
$form-switch-color: rgba($black, 0.25);
$form-switch-width: 2em;
$form-switch-padding-start: $form-switch-width + 0.5em;
$form-switch-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='3' fill='#{$form-switch-color}'/></svg>");
$form-switch-border-radius: $form-switch-width;
$form-switch-transition: background-position 0.15s ease-in-out;

$form-switch-focus-color: $input-focus-border-color;
$form-switch-focus-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='3' fill='#{$form-switch-focus-color}'/></svg>");

$form-switch-checked-color: $component-active-color;
$form-switch-checked-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='3' fill='#{$form-switch-checked-color}'/></svg>");
$form-switch-checked-bg-position: right center;
// scss-docs-end form-switch-variables

// scss-docs-start input-group-variables
$input-group-addon-padding-y: $input-padding-y;
$input-group-addon-padding-x: $input-padding-x;
$input-group-addon-font-weight: $input-font-weight;
$input-group-addon-color: $input-color;
$input-group-addon-bg: $gray-200;
$input-group-addon-border-color: $input-border-color;
// scss-docs-end input-group-variables

// scss-docs-start form-select-variables
$form-select-padding-y: $input-padding-y;
$form-select-padding-x: $input-padding-x;
$form-select-font-family: $input-font-family;
$form-select-font-size: $input-font-size;
$form-select-indicator-padding: $form-select-padding-x * 3; // Extra padding for background-image
$form-select-font-weight: $input-font-weight;
$form-select-line-height: $input-line-height;
$form-select-color: $input-color;
$form-select-bg: $input-bg;
$form-select-disabled-color: null;
$form-select-disabled-bg: $gray-200;
$form-select-disabled-border-color: $input-disabled-border-color;
$form-select-bg-position: right $form-select-padding-x center;
$form-select-bg-size: 16px 12px; // In pixels because image dimensions
$form-select-indicator-color: $gray-800;
$form-select-indicator: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'><path fill='none' stroke='#{$form-select-indicator-color}' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/></svg>");

$form-select-feedback-icon-padding-end: $form-select-padding-x * 2.5 +
  $form-select-indicator-padding;
$form-select-feedback-icon-position: center right $form-select-indicator-padding;
$form-select-feedback-icon-size: $input-height-inner-half
  $input-height-inner-half;

$form-select-border-width: $input-border-width;
$form-select-border-color: $input-border-color;
$form-select-border-radius: $input-border-radius;
$form-select-box-shadow: $box-shadow-inset;

$form-select-focus-border-color: $input-focus-border-color;
$form-select-focus-width: $input-focus-width;
$form-select-focus-box-shadow: 0 0 0 $form-select-focus-width
  $input-btn-focus-color;

$form-select-padding-y-sm: $input-padding-y-sm;
$form-select-padding-x-sm: $input-padding-x-sm;
$form-select-font-size-sm: $input-font-size-sm;
$form-select-border-radius-sm: $input-border-radius-sm;

$form-select-padding-y-lg: $input-padding-y-lg;
$form-select-padding-x-lg: $input-padding-x-lg;
$form-select-font-size-lg: $input-font-size-lg;
$form-select-border-radius-lg: $input-border-radius-lg;

$form-select-transition: $input-transition;
// scss-docs-end form-select-variables

// scss-docs-start form-range-variables
$form-range-track-width: 100%;
$form-range-track-height: 0.5rem;
$form-range-track-cursor: pointer;
$form-range-track-bg: $gray-300;
$form-range-track-border-radius: 1rem;
$form-range-track-box-shadow: $box-shadow-inset;

$form-range-thumb-width: 1rem;
$form-range-thumb-height: $form-range-thumb-width;
$form-range-thumb-bg: $component-active-bg;
$form-range-thumb-border: 0;
$form-range-thumb-border-radius: 1rem;
$form-range-thumb-box-shadow: 0 0.1rem 0.25rem rgba($black, 0.1);
$form-range-thumb-focus-box-shadow: 0 0 0 1px $body-bg, $input-focus-box-shadow;
$form-range-thumb-focus-box-shadow-width: $input-focus-width; // For focus box shadow issue in Edge
$form-range-thumb-active-bg: tint-color($component-active-bg, 70%);
$form-range-thumb-disabled-bg: $gray-500;
$form-range-thumb-transition: background-color 0.15s ease-in-out,
  border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
// scss-docs-end form-range-variables

// scss-docs-start form-file-variables
$form-file-button-color: $input-color;
$form-file-button-bg: $input-group-addon-bg;
$form-file-button-hover-bg: shade-color($form-file-button-bg, 5%);
// scss-docs-end form-file-variables

// scss-docs-start form-floating-variables
$form-floating-height: add(3.5rem, $input-height-border);
$form-floating-line-height: 1.25;
$form-floating-padding-x: $input-padding-x;
$form-floating-padding-y: 1rem;
$form-floating-input-padding-t: 1.625rem;
$form-floating-input-padding-b: 0.625rem;
$form-floating-label-opacity: 0.65;
$form-floating-label-transform: scale(0.85) translateY(-0.5rem)
  translateX(0.15rem);
$form-floating-transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
// scss-docs-end form-floating-variables

// Form validation

// scss-docs-start form-feedback-variables
$form-feedback-margin-top: $form-text-margin-top;
$form-feedback-font-size: $form-text-font-size;
$form-feedback-font-style: $form-text-font-style;
$form-feedback-valid-color: $success;
$form-feedback-invalid-color: $danger;

$form-feedback-icon-valid-color: $form-feedback-valid-color;
$form-feedback-icon-valid: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'><path fill='#{$form-feedback-icon-valid-color}' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/></svg>");
$form-feedback-icon-invalid-color: $form-feedback-invalid-color;
$form-feedback-icon-invalid: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='#{$form-feedback-icon-invalid-color}'><circle cx='6' cy='6' r='4.5'/><path stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/><circle cx='6' cy='8.2' r='.6' fill='#{$form-feedback-icon-invalid-color}' stroke='none'/></svg>");
// scss-docs-end form-feedback-variables

// scss-docs-start form-validation-states
$form-validation-states: (
  "valid": (
    "color": $form-feedback-valid-color,
    "icon": $form-feedback-icon-valid,
  ),
  "invalid": (
    "color": $form-feedback-invalid-color,
    "icon": $form-feedback-icon-invalid,
  ),
);
